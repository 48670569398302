section#incentives {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: calc(100vw - (64px * 2));
  height: auto;
  padding: 32px 64px 64px 64px;
}
@media screen and (max-width: 550px) {
  section#incentives {
    width: 100vw;
    padding: 32px 0;
  }
}
section#incentives h4 {
  margin-bottom: 0;
  font-size: 1.6em;
  color: white;
  font-weight: 600;
  text-align: center;
}
section#incentives p {
  color: white;
  margin-bottom: 32px;
}
@media screen and (max-width: 750px) {
  section#incentives p {
    font-size: 12px;
  }
}
section#incentives div#incentives-table {
  display: flex;
  flex-flow: column nowrap;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 32px;
  padding: 32px 32px 16px 32px;
}
@media screen and (max-width: 750px) {
  section#incentives div#incentives-table {
    width: calc(100vw - 48px);
    padding: 16px 32px 8px 16px;
    border-radius: unset;
  }
}
section#incentives div#incentives-table div#incentives-table-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 16px;
}
section#incentives div#incentives-table div#incentives-table-header p {
  text-align: right;
  padding-right: 16px;
  width: 180px;
  margin-bottom: 0;
}
@media screen and (max-width: 720px) {
  section#incentives div#incentives-table div#incentives-table-header p {
    width: 80px;
  }
}
section#incentives div#incentives-table div#incentives-table-header p:first-child {
  text-align: left;
  width: 148px;
}
@media screen and (max-width: 720px) {
  section#incentives div#incentives-table div#incentives-table-header p:first-child {
    width: 32px;
  }
}
section#incentives div#incentives-table div#incentives-table-header p:last-child {
  padding-right: 0;
  width: 248px;
}
@media screen and (max-width: 720px) {
  section#incentives div#incentives-table div#incentives-table-header p:last-child {
    width: 80px;
  }
}
section#incentives div#incentives-table div.incentives-table-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
section#incentives div#incentives-table div.incentives-table-row a.incentives-logo {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 148px;
  height: 72px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 16px;
}
@media screen and (max-width: 750px) {
  section#incentives div#incentives-table div.incentives-table-row a.incentives-logo {
    height: 48px;
    width: 72px;
    border-radius: 8px;
  }
}
section#incentives div#incentives-table div.incentives-table-row a.incentives-logo:hover {
  border: 1px solid rgba(255, 255, 255, 0.4);
}
section#incentives div#incentives-table div.incentives-table-row a.incentives-logo picture, section#incentives div#incentives-table div.incentives-table-row a.incentives-logo img {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  max-height: 40px;
  max-width: 124px;
}
@media screen and (max-width: 750px) {
  section#incentives div#incentives-table div.incentives-table-row a.incentives-logo picture, section#incentives div#incentives-table div.incentives-table-row a.incentives-logo img {
    max-width: 48px;
    max-height: 32px;
  }
}
section#incentives div#incentives-table div.incentives-table-row p {
  text-align: right;
  padding-right: 16px;
  margin: 8px 8px 0 0;
  width: 180px;
}
@media screen and (max-width: 500px) {
  section#incentives div#incentives-table div.incentives-table-row p {
    width: 80px;
    padding-right: 4px;
  }
}
section#incentives div#incentives-table div.incentives-table-row div.incentives-reward {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}
section#incentives div#incentives-table div.incentives-table-row div.incentives-reward p {
  width: 200px;
  padding-right: 0;
}
@media screen and (max-width: 720px) {
  section#incentives div#incentives-table div.incentives-table-row div.incentives-reward p {
    width: 80px;
  }
}
section#incentives div#incentives-table div.incentives-table-row div.incentives-reward picture, section#incentives div#incentives-table div.incentives-table-row div.incentives-reward img {
  width: 24px;
  height: 24px;
  border-radius: 40px;
  margin: 4px 0 0 0;
}

/*# sourceMappingURL=Incentives.css.map */
