section#reInventingMoney {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: -15vh;
  width: 100vw;
  height: auto;
  z-index: 9;
}
section#reInventingMoney img.containerPadding {
  width: 100vw;
  height: auto;
}
section#reInventingMoney img.containerPadding:last-child {
  margin-top: -1px;
}
section#reInventingMoney div#reInventingMoneyContent {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;
  width: 80vw;
  padding: 5vh 10vw;
  background-color: rgba(255, 255, 255, 0.9);
}
@media screen and (max-width: 2100px) {
  section#reInventingMoney div#reInventingMoneyContent {
    flex-flow: row wrap;
  }
}
@media screen and (max-width: 1500px) {
  section#reInventingMoney div#reInventingMoneyContent {
    padding: 5vw;
    width: 90vw;
  }
}
section#reInventingMoney div#reInventingMoneyContent div.reInventingMoneyContentItemContainer {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 64px;
  width: 450px;
  height: auto;
}
@media screen and (max-width: 1300px) {
  section#reInventingMoney div#reInventingMoneyContent div.reInventingMoneyContentItemContainer {
    margin: 48px;
  }
}
@media screen and (max-width: 800px) {
  section#reInventingMoney div#reInventingMoneyContent div.reInventingMoneyContentItemContainer {
    margin: 48px 0;
  }
}
@media screen and (max-width: 1300px) {
  section#reInventingMoney div#reInventingMoneyContent div.reInventingMoneyContentItemContainer {
    width: calc(50vw - (4vw + 128px));
  }
}
@media screen and (max-width: 800px) {
  section#reInventingMoney div#reInventingMoneyContent div.reInventingMoneyContentItemContainer {
    width: 80vw;
  }
}
section#reInventingMoney div#reInventingMoneyContent div.reInventingMoneyContentItemContainer img {
  width: 150px;
}
section#reInventingMoney div#reInventingMoneyContent div.reInventingMoneyContentItemContainer h6 {
  font-size: 1.2em;
  margin: 16px 0 8px 0;
  font-size: 1.2em;
  margin: 16px 0 8px 0;
  color: black;
  font-weight: 600;
}
section#reInventingMoney div#reInventingMoneyContent div.reInventingMoneyContentItemContainer p {
  position: relative;
  font-size: 1em;
  color: black;
  font-weight: 400;
}

div#fundingGrowthTogetherContainer {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.9);
}

div#fundingGrowthTogetherTextContainer {
  margin-bottom: 64px;
  width: 1028px;
}
@media screen and (max-width: 1300px) {
  div#fundingGrowthTogetherTextContainer {
    width: calc(100vw - (10vw + 128px));
  }
}
@media screen and (max-width: 800px) {
  div#fundingGrowthTogetherTextContainer {
    width: 80vw;
  }
}
div#fundingGrowthTogetherTextContainer h4 {
  font-size: 1.6em;
  margin-bottom: 8px;
  color: #7161ef;
}
div#fundingGrowthTogetherTextContainer p {
  font-size: 1.2em;
}

/*# sourceMappingURL=ReInventingMoney.css.map */
