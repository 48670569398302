section#futureIsMutual {
  position: relative;
  width: calc(100vw - (64px * 2));
  height: 600px;
  padding: 64px;
}
@media screen and (max-width: 800px) {
  section#futureIsMutual {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 800px) {
  section#futureIsMutual {
    height: 500px;
  }
}
section#futureIsMutual img {
  position: absolute;
  left: 30%;
  top: 50%;
  transform: translate(-70%, -50%);
  -moz-transform: translate(-70%, -50%);
  -o-transform: translate(-70%, -50%);
  -webkit-transform: translate(-70%, -50%);
  margin-right: -48px;
  height: 500px;
  opacity: 0.5;
  z-index: 6;
}
@media screen and (max-width: 1100px) {
  section#futureIsMutual img {
    left: 25%;
    transform: translate(-75%, -50%);
    -moz-transform: translate(-75%, -50%);
    -o-transform: translate(-75%, -50%);
    -webkit-transform: translate(-75%, -50%);
  }
}
@media screen and (max-width: 1100px) {
  section#futureIsMutual img {
    height: 400px;
  }
}
@media screen and (max-width: 1100px) {
  section#futureIsMutual img {
    opacity: 0.25;
  }
}
@media screen and (max-width: 800px) {
  section#futureIsMutual img {
    opacity: 0.1;
  }
}
section#futureIsMutual div#futureIsMutualContentContainerContainer {
  position: absolute;
  left: 60%;
  top: 50%;
  transform: translate(-40%, -50%);
  -moz-transform: translate(-40%, -50%);
  -o-transform: translate(-40%, -50%);
  -webkit-transform: translate(-40%, -50%);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  padding: 16px;
  border-radius: 32px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  z-index: 8;
}
@media screen and (max-width: 1100px) {
  section#futureIsMutual div#futureIsMutualContentContainerContainer {
    left: 50%;
    transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
  }
}
section#futureIsMutual div#futureIsMutualContentContainer {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.4);
}
section#futureIsMutual div#futureIsMutualContentContainer h4 {
  margin: 16px 0 32px 0;
  font-size: 28px;
  color: white;
  font-weight: 600;
}
@media screen and (max-width: 1100px) {
  section#futureIsMutual div#futureIsMutualContentContainer h4 {
    font-size: 1.6em;
  }
}
@media screen and (max-width: 800px) {
  section#futureIsMutual div#futureIsMutualContentContainer h4 {
    font-size: 1.4em;
  }
}
section#futureIsMutual div#futureIsMutualContentContainer form {
  display: flex;
  flex-flow: row nowrap;
  padding: 16px;
}
@media screen and (max-width: 800px) {
  section#futureIsMutual div#futureIsMutualContentContainer form {
    flex-flow: column nowrap;
  }
}
section#futureIsMutual div#futureIsMutualContentContainer form input#email {
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  outline: none;
  background: none;
  color: white;
  transition: 0.25s;
  margin-right: 16px;
  padding: 10px 16px;
  min-width: 300px;
  font-size: 18px;
}
section#futureIsMutual div#futureIsMutualContentContainer form input#email::placeholder {
  color: #d9d9d9;
}
section#futureIsMutual div#futureIsMutualContentContainer form input#email:focus {
  border: 1px solid white;
}
section#futureIsMutual div#futureIsMutualContentContainer form input#email:hover {
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.25);
}
@media screen and (max-width: 800px) {
  section#futureIsMutual div#futureIsMutualContentContainer form input#email {
    margin: 0 0 16px 0;
  }
}
@media screen and (max-width: 500px) {
  section#futureIsMutual div#futureIsMutualContentContainer form input#email {
    min-width: 180px;
    align-self: stretch;
    width: auto;
  }
}
section#futureIsMutual div#futureIsMutualContentContainer form input#submit {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  width: fit-content;
  font-size: 18px;
  border-radius: 16px;
  cursor: pointer;
  font-weight: 700;
  background-color: #957fef;
  color: white;
  border: none;
  outline: none;
}
@media screen and (max-width: 800px) {
  section#futureIsMutual div#futureIsMutualContentContainer form input#submit {
    width: auto;
  }
}
section#futureIsMutual div#futureIsMutualContentContainer form input#submit:hover {
  background-color: #7161ef;
  transition: 0.5s;
}
section#futureIsMutual div#futureIsMutualContentContainer form input#submit:active {
  background-color: #7161ef;
  transition: 0s;
}

/*# sourceMappingURL=FutureIsMutual.css.map */
