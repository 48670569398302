section#exchanges {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 32px 64px 64px 64px;
}
section#exchanges h4 {
  margin-bottom: 0;
  font-size: 1.6em;
  color: white;
  font-weight: 600;
  text-align: center;
}
section#exchanges p {
  color: white;
  margin-bottom: 32px;
  text-align: center;
}
@media screen and (max-width: 720px) {
  section#exchanges p {
    font-size: 12px;
  }
}
section#exchanges div#exchange-logos {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: auto;
}
section#exchanges div#exchange-logos a.exchange-logo {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 148px;
  height: 72px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  margin: 8px;
}
section#exchanges div#exchange-logos a.exchange-logo:hover {
  border: 1px solid rgba(255, 255, 255, 0.4);
}
section#exchanges div#exchange-logos a.exchange-logo picture, section#exchanges div#exchange-logos a.exchange-logo img {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  max-height: 40px;
  max-width: 124px;
}

/*# sourceMappingURL=DEXES.css.map */
