section#tokenomics {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: calc(100vw - (64px * 2));
  height: auto;
  padding: 32px 64px 64px 64px;
}
@media screen and (max-width: 550px) {
  section#tokenomics {
    width: calc(100vw - (16px * 2));
    padding: 16px;
  }
}
section#tokenomics h4 {
  margin-bottom: 32px;
  font-size: 1.6em;
  color: white;
  font-weight: 600;
  text-align: center;
}
section#tokenomics p {
  color: white;
  margin-bottom: 32px;
}
section#tokenomics div#tokenomicsItemsContainer {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  width: calc(90% + (8px * 4));
}
@media screen and (max-width: 550px) {
  section#tokenomics div#tokenomicsItemsContainer {
    width: calc(80% + (16px * 2));
    padding: 16px;
  }
}
section#tokenomics div#tokenomicsItemsContainer div.tokenomicsItem {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin: 16px;
  width: auto;
  height: auto;
  padding: 0;
}
@media screen and (max-width: 550px) {
  section#tokenomics div#tokenomicsItemsContainer div.tokenomicsItem {
    align-self: stretch;
  }
}
section#tokenomics div#tokenomicsItemsContainer picture,
section#tokenomics div#tokenomicsItemsContainer img {
  border-radius: 16px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: auto;
}

/*# sourceMappingURL=Tokenomics.css.map */
