section#stakeholders {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: calc(100vw - (64px * 2));
  height: auto;
  padding: 32px 64px 64px 64px;
}
@media screen and (max-width: 550px) {
  section#stakeholders {
    width: calc(100vw - (16px * 2));
    padding: 16px;
  }
}
section#stakeholders h4 {
  margin-bottom: 32px;
  font-size: 1.6em;
  color: white;
  font-weight: 600;
  text-align: center;
}
section#stakeholders div#stakeholdersItemsContainer {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: calc(80% + (8px * 4));
}
@media screen and (max-width: 550px) {
  section#stakeholders div#stakeholdersItemsContainer {
    width: calc(80% + (16px * 2));
    padding: 16px;
  }
}
section#stakeholders div#stakeholdersItemsContainer a.stakeholderItem {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin: 8px;
  width: auto;
  height: auto;
  padding: 32px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 16px;
}
@media screen and (max-width: 1200px) {
  section#stakeholders div#stakeholdersItemsContainer a.stakeholderItem {
    width: auto;
  }
}
@media screen and (max-width: 550px) {
  section#stakeholders div#stakeholdersItemsContainer a.stakeholderItem {
    width: auto;
    align-self: stretch;
  }
}
section#stakeholders div#stakeholdersItemsContainer a.stakeholderItem:hover {
  border: 1px solid rgba(255, 255, 255, 0.4);
}
section#stakeholders div#stakeholdersItemsContainer picture,
section#stakeholders div#stakeholdersItemsContainer img {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-around;
  width: auto;
  height: 48px;
}

/*# sourceMappingURL=Stakeholders.css.map */
