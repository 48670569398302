nav#nav {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 24px 24px 24px 24px;
  width: calc(100vw - 48px);
  height: auto;
  z-index: 999;
}

img#logo {
  width: auto;
  margin: 10px;
  height: 56px;
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  img#logo {
    height: 40px;
  }
}
@media screen and (max-width: 400px) {
  img#logo {
    height: 32px;
  }
}

div#nav-link-list {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: auto;
}
@media screen and (max-width: 500px) {
  div#nav-link-list {
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
  }
}
@media screen and (max-width: 310px) {
  div#nav-link-list {
    width: 50px;
  }
}
div#nav-link-list a {
  height: 41px;
}
div#nav-link-list svg {
  margin: 0 8px;
  padding: 0 12px;
  height: 100%;
  font-size: 1.2em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: white;
  border-radius: 16px;
  font-family: "Righteous", "Space Mono", "Poppins", cursive;
  font-weight: 400;
  transition: 0.5s;
}
@media screen and (max-width: 600px) {
  div#nav-link-list svg {
    margin: 0 4px;
  }
}
@media screen and (max-width: 400px) {
  div#nav-link-list svg {
    font-size: 1em;
    padding: 4px 8px;
  }
}
@media screen and (max-width: 400px) {
  div#nav-link-list svg {
    font-size: 1em;
    padding: 4px 8px;
  }
}
@media screen and (max-width: 310px) {
  div#nav-link-list svg {
    width: 1.2em;
  }
}
div#nav-link-list svg:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.nav-text-link {
  display: flex;
  flex-flow: nowrap;
  align-items: center;
  color: white;
  border-radius: 16px;
  font-weight: 400;
  padding: 0 12px;
  margin: 0 4px;
  transition: 0.5s;
}
.nav-text-link:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/*# sourceMappingURL=Nav.css.map */
