section#tge {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: calc(100vw - (64px * 2));
  height: auto;
  padding: 32px 64px 64px 64px;
}
@media screen and (max-width: 750px) {
  section#tge {
    width: 100vw;
    padding: 32px 0;
  }
}
section#tge h4 {
  margin-bottom: 0;
  font-size: 1.6em;
  color: white;
  font-weight: 600;
  text-align: center;
}
section#tge p {
  color: white;
  margin-bottom: 32px;
}
section#tge p:first-child {
  width: 148px;
}
@media screen and (max-width: 750px) {
  section#tge p {
    font-size: 12px;
  }
}
section#tge div#tge-table {
  display: flex;
  flex-flow: column nowrap;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 32px;
  padding: 32px 32px 16px 32px;
}
@media screen and (max-width: 750px) {
  section#tge div#tge-table {
    border-radius: unset;
    width: calc(100vw - 32px);
    padding: 16px 32px 8px 16px;
  }
}
section#tge div#tge-table div#tge-table-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 16px;
}
section#tge div#tge-table div#tge-table-header p {
  text-align: right;
  width: 100px;
  padding-right: 16px;
  margin-bottom: 0;
}
@media screen and (max-width: 500px) {
  section#tge div#tge-table div#tge-table-header p {
    width: 80px;
  }
}
section#tge div#tge-table div#tge-table-header p:first-child {
  text-align: left;
  width: 148px;
}
@media screen and (max-width: 750px) {
  section#tge div#tge-table div#tge-table-header p:first-child {
    width: 74px;
  }
}
section#tge div#tge-table div#tge-table-header p:last-child {
  width: 160px;
  padding-right: 0;
}
@media screen and (max-width: 500px) {
  section#tge div#tge-table div#tge-table-header p:last-child {
    width: 80px;
  }
}
section#tge div#tge-table div.tge-table-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
section#tge div#tge-table div.tge-table-row a.launchpad-logo {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 148px;
  height: 72px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 16px;
}
@media screen and (max-width: 750px) {
  section#tge div#tge-table div.tge-table-row a.launchpad-logo {
    height: 48px;
    width: 72px;
    border-radius: 8px;
  }
}
section#tge div#tge-table div.tge-table-row a.launchpad-logo:hover {
  border: 1px solid rgba(255, 255, 255, 0.4);
}
section#tge div#tge-table div.tge-table-row a.launchpad-logo picture, section#tge div#tge-table div.tge-table-row a.launchpad-logo img {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  max-height: 40px;
  max-width: 124px;
}
@media screen and (max-width: 750px) {
  section#tge div#tge-table div.tge-table-row a.launchpad-logo picture, section#tge div#tge-table div.tge-table-row a.launchpad-logo img {
    max-width: 48px;
    max-height: 32px;
  }
}
section#tge div#tge-table div.tge-table-row p {
  width: 100px;
  text-align: right;
  padding-right: 16px;
  margin-bottom: 0;
}
@media screen and (max-width: 500px) {
  section#tge div#tge-table div.tge-table-row p {
    width: 80px;
  }
}
section#tge div#tge-table div.tge-table-row p:last-child {
  width: 160px;
  padding-right: unset;
}
@media screen and (max-width: 500px) {
  section#tge div#tge-table div.tge-table-row p:last-child {
    width: 80px;
  }
}

/*# sourceMappingURL=TGE.css.map */
