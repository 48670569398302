section#source-utility {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin: 48px;
}
section#source-utility h4 {
  font-size: 1.6em;
  color: white;
  font-weight: 600;
  text-align: center;
  margin-bottom: 32px;
}
section#source-utility div.source-utility-info-items {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
}
section#source-utility div.source-utility-info-items div.source-utility-info-item {
  display: flex;
  flex-flow: column nowrap;
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  color: white;
  margin: 16px;
  width: 300px;
}
@media screen and (max-width: 550px) {
  section#source-utility div.source-utility-info-items div.source-utility-info-item {
    width: 200px;
    margin: 8px;
  }
}
section#source-utility div.source-utility-info-items div.source-utility-info-item h5 {
  font-size: 18px;
  margin-bottom: 8px;
}
@media screen and (max-width: 550px) {
  section#source-utility div.source-utility-info-items div.source-utility-info-item h5 {
    font-size: 16px;
  }
}
@media screen and (max-width: 550px) {
  section#source-utility div.source-utility-info-items div.source-utility-info-item p {
    font-size: 12px;
  }
}

/*# sourceMappingURL=SourceUtility.css.map */
