section#stakeInRealEconomy {
  position: relative;
  width: 100vw;
  height: 60vh;
  min-height: 600px;
}
section#stakeInRealEconomy img {
  position: absolute;
  left: 58%;
  top: 65%;
  transform: translate(-42%, -35%);
  -moz-transform: translate(-42%, -35%);
  -o-transform: translate(-42%, -35%);
  -webkit-transform: translate(-42%, -35%);
  opacity: 0.5;
  height: 100%;
  min-width: 60%;
  max-width: 80%;
  z-index: -10;
}
@media screen and (max-width: 1900px) {
  section#stakeInRealEconomy img {
    top: 60%;
    transform: translate(-42%, -40%);
    -moz-transform: translate(-42%, -40%);
    -o-transform: translate(-42%, -40%);
    -webkit-transform: translate(-42%, -40%);
  }
}
section#stakeInRealEconomy h4 {
  position: relative;
  margin: 8vh 10vw 0 10vw;
  font-size: 3em;
  max-width: 700px;
  font-weight: 600;
  color: white;
  line-height: 100%;
  z-index: -8;
}
@media screen and (max-width: 1000px) {
  section#stakeInRealEconomy h4 {
    font-size: 2.4em;
  }
}
@media screen and (max-width: 650px) {
  section#stakeInRealEconomy h4 {
    font-size: 2em;
    max-width: 250px;
  }
}
section#stakeInRealEconomy p {
  position: relative;
  margin: 32px 10vw 0 10vw;
  font-size: 1.4em;
  max-width: 700px;
  font-weight: 300;
  line-height: 120%;
  color: white;
}
@media screen and (max-width: 1000px) {
  section#stakeInRealEconomy p {
    font-size: 1.4em;
  }
}
@media screen and (max-width: 650px) {
  section#stakeInRealEconomy p {
    font-size: 1em;
    max-width: 450px;
  }
}
section#stakeInRealEconomy form {
  display: flex;
  flex-flow: row nowrap;
  margin: 48px 10vw 0 10vw;
}
@media screen and (max-width: 800px) {
  section#stakeInRealEconomy form {
    flex-flow: column nowrap;
  }
}
section#stakeInRealEconomy form input#email {
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  outline: none;
  background: none;
  color: white;
  transition: 0.25s;
  margin-right: 16px;
  padding: 10px 16px;
  min-width: 300px;
  font-size: 18px;
}
section#stakeInRealEconomy form input#email::placeholder {
  color: #d9d9d9;
}
section#stakeInRealEconomy form input#email:focus {
  border: 1px solid white;
}
section#stakeInRealEconomy form input#email:hover {
  box-shadow: 0 0 8px rgba(255, 255, 255, 0.25);
}
@media screen and (max-width: 800px) {
  section#stakeInRealEconomy form input#email {
    margin: 0 0 16px 0;
  }
}
@media screen and (max-width: 500px) {
  section#stakeInRealEconomy form input#email {
    min-width: 180px;
    align-self: stretch;
    width: auto;
  }
}
section#stakeInRealEconomy form input#submit {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  width: fit-content;
  font-size: 18px;
  border-radius: 16px;
  cursor: pointer;
  font-weight: 700;
  background-color: #957fef;
  color: white;
  border: none;
  outline: none;
}
@media screen and (max-width: 800px) {
  section#stakeInRealEconomy form input#submit {
    width: auto;
  }
}
section#stakeInRealEconomy form input#submit:hover {
  background-color: #7161ef;
  transition: 0.5s;
}
section#stakeInRealEconomy form input#submit:active {
  background-color: #7161ef;
  transition: 0s;
}

/*# sourceMappingURL=StakeInRealEconomy.css.map */
