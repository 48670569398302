section#socialLinks {
  width: calc(100vw - (64px * 2));
  padding: 64px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
}
@media screen and (max-width: 550px) {
  section#socialLinks {
    padding: 16px;
    width: calc(100vw - (16px * 2));
  }
}
section#socialLinks h4 {
  font-size: 1.6em;
  color: white;
  font-weight: 600;
  text-align: center;
  margin-bottom: 32px;
}
section#socialLinks div#socialLinksContainer {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
  height: auto;
  width: calc(80% + (8px * 4));
  max-width: calc((500px * 2) + (8px * 4));
}
@media screen and (max-width: 1200px) {
  section#socialLinks div#socialLinksContainer {
    flex-flow: column nowrap;
    align-items: center;
  }
}
section#socialLinks div#socialLinksContainer::after {
  content: "";
  width: 40%;
  margin: 8px;
  max-width: 500px;
  height: 50px;
  border: 1px solid rgba(0, 0, 0, 0);
  padding: 24px;
}
section#socialLinks div#socialLinksContainer a.socialItemContainer {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 24px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  color: white;
  cursor: pointer;
  fill: white;
  width: 40%;
  margin: 8px;
  max-width: 500px;
  transition: 0.2s;
}
@media screen and (max-width: 1200px) {
  section#socialLinks div#socialLinksContainer a.socialItemContainer {
    width: 360px;
  }
}
@media screen and (max-width: 550px) {
  section#socialLinks div#socialLinksContainer a.socialItemContainer {
    width: auto;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
  }
  section#socialLinks div#socialLinksContainer a.socialItemContainer svg {
    margin-bottom: 16px;
  }
}
section#socialLinks div#socialLinksContainer a.socialItemContainer:hover {
  border: 1px solid white;
  color: #957fef;
  fill: #957fef;
  background-color: white;
}
section#socialLinks div#socialLinksContainer a.socialItemContainer svg {
  width: 48px;
  height: 48px;
  margin-right: 24px;
}
section#socialLinks div#socialLinksContainer a.socialItemContainer div.socialItemTextContainer {
  height: auto;
  max-width: 280px;
}
section#socialLinks div#socialLinksContainer a.socialItemContainer div.socialItemTextContainer h6 {
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 2px;
  width: auto;
  text-transform: capitalize;
}
section#socialLinks div#socialLinksContainer a.socialItemContainer div.socialItemTextContainer p {
  font-size: 1em;
  font-weight: 400;
  width: auto;
}

/*# sourceMappingURL=SocialLinks.css.map */
