#howItWorks {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 100vw;
  height: auto;
}
#howItWorks img.containerPadding {
  width: 100vw;
  height: auto;
}
#howItWorks img.containerPadding:last-child {
  margin-top: -1px;
}

#howItWorksContent {
  display: flex;
  flex-flow: row nowrap;
  align-content: flex-end;
  justify-content: center;
  width: 90vw;
  height: auto;
  padding: 5vw 10vw;
  background-color: rgba(255, 255, 255, 0.9);
}
@media screen and (max-width: 1000px) {
  #howItWorksContent {
    align-content: center;
  }
}
@media screen and (max-width: 700px) {
  #howItWorksContent {
    flex-flow: column nowrap;
  }
}
@media screen and (max-width: 1000px) {
  #howItWorksContent {
    width: 80vw;
  }
}
@media screen and (max-width: 1000px) {
  #howItWorksContent {
    padding: 5vw 10vw;
  }
}
#howItWorksContent img#howItWorksContentImg {
  align-self: flex-end;
  width: 30%;
  height: auto;
}
@media screen and (max-width: 1000px) {
  #howItWorksContent img#howItWorksContentImg {
    align-self: center;
  }
}
@media screen and (max-width: 700px) {
  #howItWorksContent img#howItWorksContentImg {
    align-self: flex-start;
  }
}
@media screen and (max-width: 1000px) {
  #howItWorksContent img#howItWorksContentImg {
    width: 50%;
    position: absolute;
    left: -20%;
  }
}
@media screen and (max-width: 700px) {
  #howItWorksContent img#howItWorksContentImg {
    position: unset;
    width: 80%;
    max-width: 300px;
    margin-top: 48px;
  }
}
#howItWorksContent div#howItWorksContentTextContainer {
  display: flex;
  flex-flow: column nowrap;
  margin-left: 6em;
  max-width: 600px;
}
@media screen and (max-width: 1000px) {
  #howItWorksContent div#howItWorksContentTextContainer {
    margin-left: 35%;
  }
}
@media screen and (max-width: 700px) {
  #howItWorksContent div#howItWorksContentTextContainer {
    margin: 48px 0;
  }
}
#howItWorksContent h4#howItWorksContentTitleText {
  font-size: 3em;
}
@media screen and (max-width: 1300px) {
  #howItWorksContent h4#howItWorksContentTitleText {
    font-size: 2em;
  }
}
@media screen and (max-width: 1000px) {
  #howItWorksContent h4#howItWorksContentTitleText {
    font-size: 1.6em;
  }
}
#howItWorksContent div#howItWorksContentDescriptionTextContainer p {
  margin-bottom: 1em;
  font-size: 1.2em;
}
#howItWorksContent div#howItWorksContentDescriptionTextContainer p:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 1300px) {
  #howItWorksContent div#howItWorksContentDescriptionTextContainer p {
    font-size: 1.15em;
  }
}
@media screen and (max-width: 1000px) {
  #howItWorksContent div#howItWorksContentDescriptionTextContainer p {
    font-size: 1.1em;
  }
}

#howItWorksNewsletterCTA {
  display: flex;
  flex-flow: row nowrap;
  background-color: rgba(255, 255, 255, 0.9);
  width: 100vw;
  height: auto;
}

/*# sourceMappingURL=HowItWorks.css.map */
