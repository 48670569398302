div#contracts {
  height: auto;
  flex-flow: row wrap;
  max-width: 90vw;
  z-index: 99;
  padding: 16px 0 16px 32px;
  font-size: 12px;
  margin-top: 6px;
}
@media screen and (max-width: 600px) {
  div#contracts {
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
  }
}
div#contracts p {
  display: inline;
  margin-right: 12px;
}
@media screen and (max-width: 600px) {
  div#contracts p {
    display: block;
    text-align: center;
  }
}
div#contracts div.contract-item {
  display: inline;
  margin-right: 12px;
  text-align: center;
}
@media screen and (max-width: 600px) {
  div#contracts div.contract-item {
    display: block;
    margin: 16px 0;
  }
}
div#contracts a {
  color: white;
  padding: 4px 8px;
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  font-weight: lighter;
  white-space: nowrap;
}
div#contracts a:hover {
  border: 1px solid white;
}
div#contracts a span {
  margin-right: 4px;
  font-weight: normal;
}

div#tgePage header {
  width: 100%;
  height: auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin: 128px 0 64px 0;
  color: white;
}
@media screen and (max-width: 550px) {
  div#tgePage header {
    margin: 128px 0 64px 0;
  }
}
div#tgePage header h1 {
  color: white;
  font-size: 3em;
  text-align: center;
  margin-top: 48px;
}
@media screen and (max-width: 550px) {
  div#tgePage header h1 {
    font-size: 2em;
  }
}
div#tgePage header h2 {
  color: white;
  font-size: 2em;
  font-style: italic;
  text-align: center;
  font-weight: normal;
  margin: 16px 32px 0 32px;
}
@media screen and (max-width: 550px) {
  div#tgePage header h2 {
    font-size: 1.6em;
    margin: 16px 16px 0 16px;
  }
}
div#tgePage header img {
  width: 128px;
  height: 128px;
}
@media screen and (max-width: 550px) {
  div#tgePage header img {
    width: 64px;
    height: 64px;
  }
}

/*# sourceMappingURL=TGEPage.css.map */
