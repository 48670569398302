section#protocolRoles {
  height: auto;
  width: 100vw;
  display: flex;
  flex-flow: column nowrap;
  z-index: 80;
}
@media screen and (max-width: 800px) {
  section#protocolRoles {
    flex-flow: column-reverse nowrap;
  }
}

img#lineOfBusinesses {
  position: absolute;
  top: calc(100vh - 200px);
  right: 20vw;
  width: 80px;
}
@media screen and (max-width: 1300px) {
  img#lineOfBusinesses {
    top: calc(100vh - 150px);
  }
}
@media screen and (max-width: 1100px) {
  img#lineOfBusinesses {
    top: calc(100vh - 50px);
  }
}
@media screen and (max-width: 800px) {
  img#lineOfBusinesses {
    display: none;
  }
}
@media screen and (max-width: 1300px) {
  img#lineOfBusinesses {
    width: 60px;
  }
}
@media screen and (max-width: 1100px) {
  img#lineOfBusinesses {
    width: 40px;
  }
}

video#animation {
  display: block;
  position: absolute;
  top: calc(100vh + 100px);
  right: -3vw;
  width: 80vw;
  background-blend-mode: lighten;
}
@media screen and (max-width: 800px) {
  video#animation {
    position: relative;
    align-self: flex-end;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 800px) {
  video#animation {
    top: unset;
    width: 120vw;
  }
}

.protocolRolesCards {
  display: flex;
  flex-flow: column nowrap;
}
@media screen and (max-width: 800px) {
  .protocolRolesCards {
    justify-content: center;
  }
}

.protocolRolesCard {
  margin: 8vw;
  padding: 100px 24px 72px 24px;
  width: 365px;
  min-height: 140px;
  height: auto;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.2));
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  margin: 8vw;
  border-radius: 32px;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
}
.protocolRolesCard:first-child {
  margin-top: 12vw;
}
@media screen and (max-width: 1300px) {
  .protocolRolesCard:first-child {
    margin-top: 8vh;
  }
}
@media screen and (max-width: 1100px) {
  .protocolRolesCard {
    padding: 80px 24px 48px 24px;
  }
}
@media screen and (max-width: 800px) {
  .protocolRolesCard {
    margin: 80px auto;
  }
}
@media screen and (max-width: 600px) {
  .protocolRolesCard {
    margin: 32px 32px 64px 32px;
  }
}
@media screen and (max-width: 1300px) {
  .protocolRolesCard {
    width: 280px;
    min-height: 100px;
  }
}
@media screen and (max-width: 1100px) {
  .protocolRolesCard {
    width: 200px;
    min-height: 60px;
    padding: 80px 24px 48px 24px;
  }
}
@media screen and (max-width: 800px) {
  .protocolRolesCard {
    width: 50%;
  }
}
@media screen and (max-width: 600px) {
  .protocolRolesCard {
    width: auto;
  }
}
.protocolRolesCard p {
  font-size: 24px;
  font-weight: normal;
  line-height: 32px;
}
@media screen and (max-width: 1300px) {
  .protocolRolesCard p {
    font-size: 18px;
    line-height: 24px;
  }
}
.protocolRolesCard h6 {
  font-size: 24px;
  font-weight: 600;
  line-height: 100%;
  text-transform: capitalize;
  margin: 8px 0;
}
@media screen and (max-width: 1300px) {
  .protocolRolesCard h6 {
    font-size: 18px;
  }
}
.protocolRolesCard img.protocolRolesCardImg {
  margin: -130px 0 32px 20px;
  display: block;
  text-transform: capitalize;
  width: 150px;
  height: auto;
}
@media screen and (max-width: 1100px) {
  .protocolRolesCard img.protocolRolesCardImg {
    margin: -100px 0 32px 20px;
  }
}
@media screen and (max-width: 1300px) {
  .protocolRolesCard img.protocolRolesCardImg {
    width: 100px;
  }
}
@media screen and (max-width: 1100px) {
  .protocolRolesCard img.protocolRolesCardImg {
    width: 80px;
  }
}

/*# sourceMappingURL=ProtocolRoles.css.map */
