section#brand {
  width: calc(100% - 128px);
  overflow-x: hidden;
  height: auto;
  background-color: white;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 128px 64px;
}
section#brand code {
  font-family: Inconsolata, monospace;
  text-transform: uppercase;
}
section#brand h4 {
  font-size: 2em;
  margin-bottom: 16px;
  color: #595959;
}
section#brand a {
  margin-top: 8px;
  cursor: pointer;
}
section#brand a:hover {
  text-decoration: underline;
}
section#brand div {
  width: 100%;
  margin-bottom: 64px;
  display: flex;
  flex-flow: column nowrap;
}
section#brand div:last-child {
  margin-bottom: 0;
}
section#brand div.downloadButtons {
  display: flex;
  flex-flow: row nowrap;
}
section#brand div.downloadButtons button, section#brand div.downloadButtons .svg-button {
  cursor: pointer;
  margin-top: 8px;
  outline: none;
  color: blue;
  border: 1px solid #efefef;
  background-color: white;
  padding: 4px 12px;
  border-radius: 8px;
}
section#brand div.downloadButtons button:hover, section#brand div.downloadButtons .svg-button:hover {
  text-decoration: none;
  background-color: #efefef;
}
section#brand div.downloadButtons button {
  margin-left: 8px;
}
section#brand div.variations {
  display: flex;
  flex-flow: row nowrap;
}
@media (max-width: 800px) {
  section#brand div.variations {
    flex-flow: row wrap;
  }
}
section#brand div.variations div {
  flex-grow: 1;
  margin-bottom: unset;
  margin-right: 2vw;
}
@media (max-width: 800px) {
  section#brand div.variations div {
    margin-bottom: 16px;
  }
}
section#brand div div.dropdown {
  position: absolute;
  margin: 48px 0 0 64px;
  display: flex;
  flex-flow: column nowrap;
  background-color: #efefef;
  padding: 16px;
  justify-content: flex-start;
  align-items: flex-start;
  color: black;
  width: 64px;
  border-radius: 8px;
}
section#brand div div.dropdown.show {
  display: flex;
}
section#brand div div.dropdown.hide {
  display: none;
}
section#brand div div.dropdown a {
  margin: 0 0 8px 0;
}
section#brand div div.dropdown a:last-child {
  margin-bottom: 0;
}
section#brand img {
  width: calc(100% - 48px);
  min-height: 64px;
  min-width: 64px;
  max-height: 128px;
  border-radius: 8px;
  border: 1px solid #BDBDBD;
  padding: 24px;
}
section#brand img.lightBg {
  background-image: linear-gradient(45deg, #f6f6f6 25%, transparent 0, transparent 75%, #f6f6f6 0, #f6f6f6), linear-gradient(45deg, #f6f6f6 25%, transparent 0, transparent 75%, #f6f6f6 0, #f6f6f6);
  background-size: 30px 30px;
  background-position: 0 0, 45px 45px;
  background-color: white;
}
section#brand img.darkBg {
  background-image: linear-gradient(45deg, #222427 25%, transparent 0px, transparent 75%, #222427 0px, #222427), linear-gradient(45deg, #222427 25%, transparent 0px, transparent 75%, #222427 0px, #222427);
  background-size: 30px 30px;
  background-position: 0 0, 45px 45px;
  background-color: black;
}

div.brand-color {
  border: 1px solid #BDBDBD;
  border-radius: 8px;
  height: 128px;
  padding: 24px;
  text-transform: capitalize;
  font-weight: 600;
}
div.brand-color p {
  margin-bottom: 4px;
}

/*# sourceMappingURL=Brand.css.map */
