div#banners-wrap {
  position: fixed;
  flex-flow: column nowrap;
  z-index: 99;
  bottom: 0;
}

section#contracts {
  width: calc(100vw - 32px);
  height: auto;
  flex-flow: row wrap;
  z-index: 99;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 16px 0 16px 32px;
  font-size: 12px;
  overflow-x: scroll;
}
section#contracts::-webkit-scrollbar-thumb, section#contracts::-webkit-scrollbar, section#contracts::-webkit-scrollbar-track {
  background-color: transparent;
  height: 0;
}
section#contracts p {
  display: inline;
  margin-right: 12px;
}
@media screen and (max-width: 650px) {
  section#contracts p#contracts-title {
    display: none;
  }
}
section#contracts div.contract-item {
  display: inline;
}
section#contracts a {
  color: black;
  padding: 4px 8px;
  border-radius: 24px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin-right: 12px;
  font-weight: lighter;
  white-space: nowrap;
}
section#contracts a:hover {
  border: 1px solid black;
}
section#contracts a span {
  margin-right: 4px;
  font-weight: normal;
}

#banner-container {
  display: flex;
  flex-flow: row nowrap;
  width: 100vw;
  height: auto;
}

#banner {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  padding: 16px 32px;
  background-color: rgba(255, 255, 255, 0.9);
}
#banner a {
  padding: 8px 16px;
  background-color: #957fef;
  color: white;
  border-radius: 16px;
  border: none;
  outline: none;
  cursor: pointer;
  font-weight: bold;
}
#banner a:hover {
  background-color: #7161ef;
}
#banner #banner-text {
  display: flex;
  flex-flow: row nowrap;
  width: auto;
}
@media screen and (max-width: 350px) {
  #banner #banner-text h4 {
    display: none;
  }
}
#banner #banner-text p {
  margin-left: 8px;
}
@media screen and (max-width: 690px) {
  #banner #banner-text p {
    display: none;
  }
}

/*# sourceMappingURL=Banner.css.map */
